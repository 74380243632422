<template>
  <div id="login-root" class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12">
          <b-card-group>
            <b-card no-body class="pt-4 px-4 pb-1">
              <b-card-body class="pt-0 px-0">
                <h1 class="text-center mb-3">WMS</h1>

                <div v-if="companyGroups && companyGroups.length">
                  <b-row v-if="isSubmitting">
                    <b-col cols="12">
                     <loading-animation></loading-animation>
                    </b-col>
                  </b-row>

                  <div v-else>
                    <h5 class="text-center w-100">Selecione a empresa</h5>

                    <button v-for="(companyGroup, index) in companyGroups" :key="index" type="button" class="btn btn-lg btn-primary btn-block mt-3"
                      @click="login(companyGroup)">{{companyGroup.name}}</button>
                  </div>
                </div>

                <b-form
                  v-else
                  @submit.prevent="login(null)"
                  novalidate
                >
                  <b-input-group
                    class="mb-3"
                  >
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-user"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>

                    <b-form-input
                      type="text"
                      class="form-control"
                      placeholder="Username"
                      autocomplete="username email"
                      v-model="email.value"
                      required
                    />
                  </b-input-group>
                  <b-input-group
                    class="mb-4"
                  >
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-lock"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      type="password"
                      class="form-control"
                      placeholder="Password"
                      autocomplete="current-password"
                      required
                      v-model="password.value"
                    />
                  </b-input-group>
                  <b-row v-if="isSubmitting">
                    <b-col cols="12">
                     <loading-animation></loading-animation>
                    </b-col>
                  </b-row>
                  <div class="row justify-content-center" v-else>
                    <div class="col-auto">
                      <b-button variant="primary" class="px-4" type="submit">Entrar</b-button>
                    </div>
                  </div>
                </b-form>
              </b-card-body>

              <p class="text-right text-muted mb-0 mr-n3">Versão {{currentVersion}}</p>
            </b-card>
          </b-card-group>
        </div>
      </b-row>

    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
  import { auth } from '@/service'
  import { LoadingAnimation } from '@/components/loaders'
  import { httpClient } from '@/service'
  import shared from '@/shared/shared';
  //import { version } from '../../../package.json';

  export default {
    name: 'Login',
    components: { LoadingAnimation },

    data () {
      return {
        isValid: false,
        test: false,
        isSubmitting: false,
        email: {
          value: null,
          isValid: null,
        },
        password: {
          value: '',
          isValid: null,
        },
        companyGroups: [],
        currentVersion: version
      }
    },

    mounted() {
      //this.currentVersion = version;
      auth.redirectToLogin();
    },

    watch: {
      notifications (notifications) {
        notifications.forEach(notification => {
          if (notification.body.fieldErrors) {
            notification.body.fieldErrors.forEach(error => {
              this.$snotify[notification.type](error.message, error.field, notification.config)
            })
          } else {
            this.$snotify[notification.type](notification.body, notification.title, notification.config)
          }

        })
      }
    },

    computed: {
      notifications() {
        return this.$store.getters['app/notifications'];
      }
    },

    methods: {
      login(companyGroup) {
        this.isSubmitting = true;
        let url = `${process.env.VUE_APP_AUTH_API_URL}/login`;

        if (companyGroup) {
          url += `?companyGroupId=${companyGroup.id}`
        }

        httpClient.post(url, {
          email: this.email.value,
          password: this.password.value
        }).then(({ data }) => {
          shared.fetchAuthUser(data.data, httpClient).then(user => {
            this.isSubmitting = false;
            this.$notify.success('Bem vindo ' + user.name);
            this.$router.push('/dashboard');
          }).catch(error => {
            this.isSubmitting = false;

            if (error.message) {
              this.$notify.error(error)
            } else if (typeof error === 'string') {
              this.$notify.textError(error);
            }
          });
        })
        .catch((error) => {
          this.isSubmitting = false;

          if (error.response && error.response.data && error.response.data.data && error.response.data.data.companyGroups
            && error.response.data.data.companyGroups.length) {
            this.companyGroups = error.response.data.data.companyGroups;
          } else if (error.message) {
            this.$notify.error(error)
          } else {
            console.log(error);
            this.$notify.textError('Erro inesperado');
          }
        });
      }
    }
  }
 </script>

<style scoped>
  #login-root {
    background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url('../../assets/img/login-background.jpg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size:  cover;
    -o-background-size: cover;
    background-size: cover;
  }
</style>
